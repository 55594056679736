import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Nav, Navbar } from 'react-bootstrap';
import axios from 'axios';
import DOMPurify from 'dompurify';

import { ElementPanelContain, ElementTitleH1, ElementTitleH2 } from './Element';

import { UtilContent, UtilTranslate, UtilRoute } from './Util';

// Props: debug, translation_data, translation_language
function SectionBanner(props)
{
    return (
        <section className="banner-section">
            { /* XS, SM */ }
            <div className="d-block d-md-none">
                <div className="banner-video-poster"></div>
            </div>

            { /* MD and up */ }
            <div className="d-none d-md-block">
                <video className="banner-video banner-video-configurator" autoPlay loop muted playsInline poster="/video/banner_configurator.jpg">
                    <source src="/video/banner_configurator.mp4" type="video/mp4" />
                </video>
            </div>

            <div className="banner-overlay">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-10 col-lg-8 col-xl-7">
                            <div className="banner-center">
                                <div className="banner-category">
                                    {UtilTranslate(props.translation_data, 'banner_category', props.debug)}
                                </div>

                                { /* XS, SM */ }
                                <div className="d-block d-md-none">
                                    <h1 className="banner-title-mobile">{UtilTranslate(props.translation_data, 'banner_title', props.debug)}</h1>
                                    <p className="banner-text">{UtilTranslate(props.translation_data, 'banner_text', props.debug)}</p>
                                </div>

                                { /* MD */ }
                                <div className="d-none d-md-block d-lg-none">
                                    <h1 className="banner-title-tablet">{UtilTranslate(props.translation_data, 'banner_title', props.debug)}</h1>
                                    <p className="banner-text">{UtilTranslate(props.translation_data, 'banner_text', props.debug)}</p>
                                </div>

                                { /* LG, XL, XXL */ }
                                <div className="d-none d-lg-block">
                                    <h1 className="banner-title-desktop">{UtilTranslate(props.translation_data, 'banner_title', props.debug)}</h1>
                                    <p className="banner-text">{UtilTranslate(props.translation_data, 'banner_text', props.debug)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, translation_data, translation_language
function SectionContent(props)
{
    return (
        <React.Fragment>
            <section className="panel-section-last">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="panel-header">{UtilTranslate(props.translation_data, 'content_content_header', props.debug)}</div>
                        </div>
                    </div>
                    <div className="row">
                        <ElementPanelContain 
                            title={UtilTranslate(props.translation_data, 'content_customize_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_customize_text', props.debug)}
                            image="panel_customize" />
                        <ElementPanelContain 
                            title={UtilTranslate(props.translation_data, 'content_devices_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_devices_text', props.debug)}
                            image="panel_devices" />
                    </div>
                    <div className="row">
                        <ElementPanelContain 
                            title={UtilTranslate(props.translation_data, 'content_3d_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_3d_text', props.debug)}
                            image="panel_3d" />
                        <ElementPanelContain 
                            title={UtilTranslate(props.translation_data, 'content_templates_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_templates_text', props.debug)}
                            image="panel_templates" />
                    </div>
                    <div className="row">
                        <ElementPanelContain 
                            title={UtilTranslate(props.translation_data, 'content_download_pdf_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_download_pdf_text', props.debug)}
                            image="panel_download_pdf" />
                        <ElementPanelContain 
                            title={UtilTranslate(props.translation_data, 'content_email_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_email_text', props.debug)}
                            image="panel_email" />
                    </div>
                    <div className="row">
                        <ElementPanelContain 
                            title={UtilTranslate(props.translation_data, 'content_download_pbr_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_download_pbr_text', props.debug)}
                            image="panel_download_pbr" />
                        <ElementPanelContain 
                            title={UtilTranslate(props.translation_data, 'content_backend_title', props.debug)}
                            text={UtilTranslate(props.translation_data, 'content_backend_text', props.debug)}
                            image="panel_backend" />
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

// Props: debug, ip_address, translation_data, translation_language
function SectionDisclaimer(props)
{
    // Content
    let debug = props.debug;
    let ip_address = props.ip_address;
    let translation_language = props.translation_language;
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {
                try
                {
                    let params = {
                        keys: [
                            'disclaimer'
                        ],
                        language: translation_language
                    };

                    const response = await axios.get(ip_address+'/backend/customsoftware/api/content', { params });

                    content_set_data(response.data);
                    // <list> content text
                    
                    if (debug === true)
                        console.log(response.data);
                }
                
                catch (error)
                {
                    console.log(error);
                }
            }

            get();
        }
    }, [debug, ip_address, translation_language]);

    return (
        <React.Fragment>
            <section className="element-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ElementTitleH1
                                text={UtilTranslate(props.translation_data, 'navigation_disclaimer', props.debug)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            { content_data !== null &&
                                <div className="element-text" dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[0]), 'disclaimer', props.debug)}}></div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

// Props: debug, routes_urls, translation_data, translation_language
function SectionFooter(props)
{
    return (
        <section className="footer-section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="footer-title-section">
                            <Link to="https://thissen.ai" target="_blank" className="footer-title">Thissen AI<sup className="footer-title-trademark">®</sup></Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Link to={UtilRoute(props.translation_language, props.routes_urls, 'home', null)} className="footer-link">{UtilTranslate(props.translation_data, 'navigation_home', props.debug)}</Link>

                        <Link to={UtilRoute(props.translation_language, props.routes_urls, 'disclaimer', null)} className="footer-link">{UtilTranslate(props.translation_data, 'navigation_disclaimer', props.debug)}</Link>

                        <Link to={UtilRoute(props.translation_language, props.routes_urls, 'privacy', null)} className="footer-link">{UtilTranslate(props.translation_data, 'navigation_privacy', props.debug)}</Link>

                        <div className="footer-link-seperator">
                            <div className="d-none d-md-block">|</div>
                        </div>
                        
                        <Link to="https://thissen.ai" target="_blank" className="footer-link">Thissen.ai</Link>

                        <Link to="https://architectuurvisualisatie.be" target="_blank" className="footer-link">ArchitectuurVisualisatie.be</Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <hr className="footer-line" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="footer-text">
                            {UtilTranslate(props.translation_data, 'footer_content', props.debug)} ©{new Date().getFullYear()} Thissen AI®. KVK 84567791. BTW NL003984459B66. {UtilTranslate(props.translation_data, 'footer_legal', props.debug)}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div  className="col-12">
                        <div className="footer-text-location">
                            {UtilTranslate(props.translation_data, 'footer_made', props.debug)} <div className="footer-limburg"></div>. {UtilTranslate(props.translation_data, 'footer_hosted', props.debug)}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, ip_address, routes_urls, translation_data, translation_language
function SectionForm(props)
{
    const [form_name, form_set_name] = useState('');
    const [form_phone, form_set_phone] = useState('');
    const [form_email, form_set_email] = useState('');
    const [form_dropdown, form_set_dropdown] = useState('');
    const [form_description, form_set_description] = useState('');
    const [form_terms, form_set_terms] = useState(false);
    const [form_accept, form_set_accept] = useState(false);
    const [form_sent, form_set_sent] = useState(false);
    const [form_loading, form_set_loading] = useState(false);
    const [form_error, form_set_error] = useState(false);

    // Update default dropdown when translation has been loaded
    let debug = props.debug;
    let translation_data = props.translation_data;
    useEffect(() => {
        form_set_dropdown(UtilTranslate(translation_data, 'contactform_dropdown1', debug));
    }, [debug, translation_data]);

    const handleSubmit = (event) => {
        form_set_loading(true);
        
        const post = async () => {
            try
            {
                const data = {
                    name: form_name,
                    email: form_email,
                    dropdown: form_dropdown,
                    description: form_description,
                    terms: form_terms,
                    accept: form_accept
                }

                if (form_phone !== '')
                {
                    Object.assign(data, {
                        'phone': form_phone
                    });
                }

                await axios.post(props.ip_address+'/backend/customsoftware/api/form_contact', data);
                
                form_set_sent(true);
                form_set_loading(false);
            }
            
            catch (error)
            {
                console.log(error);
                form_set_error(true);
                form_set_loading(false);
            }
        }

        post();
        
        event.preventDefault();
    }

    let form_terms_class = 'form-checkbox';
    if (form_terms === true)
        form_terms_class = 'form-checkbox-active';
    
    return (
        <section className="form-section" id="contactform">
            <div className="container">
                <div className="row">
                    <div className="col-12 d-block d-md-none">
                        <div className="form-header">
                            <ElementTitleH2 text={UtilTranslate(props.translation_data, 'contactform_title', props.debug)} />
                            <p className="content-text-black" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(UtilTranslate(props.translation_data, 'contactform_text', props.debug))}}></p>
                        </div>
                    </div>
                    <div className="col-12 d-none d-md-block">
                        <div className="form-header">
                            <ElementTitleH2 text={UtilTranslate(props.translation_data, 'contactform_title', props.debug)} />
                            <p className="content-text-black" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(UtilTranslate(props.translation_data, 'contactform_text', props.debug))}}></p>
                        </div>
                    </div>
                </div>

                { form_loading === false && form_sent === false && form_error === false &&
                    <form onSubmit={event => handleSubmit(event)}>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <input
                                    className="form-singleline"
                                    name="form_name"
                                    type="text"
                                    maxLength="128"
                                    value={form_name}
                                    autoComplete="off"
                                    onChange={event => form_set_name(event.target.value)}
                                    placeholder={UtilTranslate(props.translation_data, 'contactform_name', props.debug)+' *'}
                                    required />
                            </div>
                            <div className="col-12 col-md-4">
                                <input
                                    className="form-singleline"
                                    name="form_phone"
                                    type="text"
                                    maxLength="128"
                                    value={form_phone}
                                    autoComplete="off"
                                    onChange={event => form_set_phone(event.target.value)}
                                    placeholder={UtilTranslate(props.translation_data, 'contactform_phone', props.debug)} />
                            </div>
                            <div className="col-12 col-md-4">
                                <input
                                    className="form-singleline"
                                    name="form_email"
                                    type="text"
                                    maxLength="128"
                                    value={form_email}
                                    autoComplete="off"
                                    onChange={event => form_set_email(event.target.value)}
                                    placeholder={UtilTranslate(props.translation_data, 'contactform_email', props.debug)+' *'} 
                                    required />
                            </div>
                            <div className="col-12">
                                <select
                                    className="form-singleline-dropdown"
                                    name="form_dropdown"
                                    onChange={event => form_set_dropdown(event.target.value)} >
                                    
                                    <option value={UtilTranslate(props.translation_data, 'contactform_dropdown1', props.debug)}>{UtilTranslate(props.translation_data, 'contactform_dropdown1', props.debug)}</option>

                                    <option value={UtilTranslate(props.translation_data, 'contactform_dropdown2', props.debug)}>{UtilTranslate(props.translation_data, 'contactform_dropdown2', props.debug)}</option>

                                    <option value={UtilTranslate(props.translation_data, 'contactform_dropdown3', props.debug)}>{UtilTranslate(props.translation_data, 'contactform_dropdown3', props.debug)}</option>
                                </select>
                            </div>
                            <div className="col-12">
                                <textarea
                                    className="form-multiline"
                                    name="form_description"
                                    maxLength="4096"
                                    value={form_description}
                                    onChange={event => form_set_description(event.target.value)}
                                    placeholder={UtilTranslate(props.translation_data, 'contactform_description', props.debug)+' *'} 
                                    required />
                            </div>
                            <div className="col-12">
                                <div className="form-checkbox-container">
                                    <label className={form_terms_class}>
                                        <input
                                            name="form_terms"
                                            type="checkbox"
                                            checked={form_terms}
                                            onChange={event => form_set_terms(event.target.checked)}
                                            required />
                                        <span className="form-text">{UtilTranslate(props.translation_data, 'contactform_terms', props.debug)} <a href={UtilRoute(props.translation_language, props.routes_urls, 'privacy', null)} target="_blank" rel="noopener noreferrer">{UtilTranslate(props.translation_data, 'contactform_privacy', props.debug)}</a>. *</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-12">
                                <input
                                    className="form-accept"
                                    name="form_accept"
                                    type="checkbox"
                                    checked={form_accept}
                                    onChange={event => form_set_accept(event.target.checked)} />
                            </div>
                            <div className="col-12">
                                <button onClick = {() => {}} className="form-send"><span>{UtilTranslate(props.translation_data, 'contactform_send', props.debug)}</span></button>
                            </div>
                        </div>
                    </form>
                }

                { form_loading === true &&
                    <div className="form-loading"></div>
                }

                { form_loading === false && form_error === true &&
                    <div className="form-error">{UtilTranslate(props.translation_data, 'contactform_error', props.debug)}</div>
                }

                { form_loading === false && form_error === false && form_sent === true &&
                    <div className="form-thankyou">{UtilTranslate(props.translation_data, 'contactform_complete', props.debug)}</div>
                }
            </div>
        </section>
    );
}

// Props: debug, ip_address, translation_data, translation_language
function SectionIntro(props)
{
    // Content
    let debug = props.debug;
    let ip_address = props.ip_address;
    let translation_language = props.translation_language;
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {
                try
                {
                    let params = {
                        keys: [
                            'intro'
                        ],
                        language: translation_language
                    };

                    const response = await axios.get(ip_address+'/backend/customsoftware/api/content', { params });

                    content_set_data(response.data);
                    // <list> content text
                    
                    if (debug === true)
                        console.log(response.data);
                }
                
                catch (error)
                {
                    console.log(error);
                }
            }

            get();
        }
    }, [debug, ip_address, translation_language]);

    return (
        <React.Fragment>
            <section className="panel-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="panel-header">{UtilTranslate(props.translation_data, 'content_intro_header', props.debug)}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            { /* XS, SM */ }
                            <div className="d-block d-md-none">
                                <div className="panel-intro-container-mobile">
                                    <ElementTitleH2 text={UtilTranslate(props.translation_data, 'content_intro_title', props.debug)} />

                                    { content_data !== null &&
                                        <div className="panel-text" style={{'color': 'black'}}dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[0]), 'intro', props.debug)}}></div>
                                    }
                                </div>
                            </div>

                            { /* MD, LG, XL, XXL */ }
                            <div className="d-none d-md-block">
                                <div className="panel-intro-container-desktop">
                                    <ElementTitleH2 text={UtilTranslate(props.translation_data, 'content_intro_title', props.debug)} />
                                    
                                    { content_data !== null &&
                                        <div className="panel-text" style={{'color': 'black'}}dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[0]), 'intro', props.debug)}}></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

// Props: debug, routes_urls, translation_data, translation_language
function SectionNavigation(props)
{
    // Scroll to top
    const location = useLocation();
    useEffect(() => {
        if (location['search'] === '' && location['hash'] === '')
        {
            window.scrollTo({
                top: 0,
                behavior: 'instant',
            });
        }
    }, [location]);

    // Expand (mobile)
    const [expanded, set_expanded] = useState(false);

    // Routes
    const [route_nl, route_set_nl] = useState('/nl-NL');
    const [route_en, route_set_en] = useState('/en-US');

    let debug = props.debug;
    let routes_urls = props.routes_urls;
    let translation_language = props.translation_language;
    useEffect(() => {
        if (translation_language !== null)
        {
            let url_split = location['pathname'].split('/');

            if (typeof url_split[2] === 'undefined')
            {
                // Default
                route_set_nl('/nl-NL');
                route_set_en('/en-US');
            }
            else
            {
                for (let route in routes_urls[translation_language])
                {
                    if (routes_urls[translation_language][route] === url_split[2])
                    {
                        if (debug === true)
                        {
                            console.log(routes_urls['nl-NL'][route]);
                            console.log(routes_urls['en-US'][route]);
                        }

                        let new_route_nl = '/nl-NL/'+routes_urls['nl-NL'][route];
                        let new_route_en = '/en-US/'+routes_urls['en-US'][route];

                        // Add slug
                        if (typeof url_split[3] !== 'undefined')
                        {
                            new_route_nl += '/'+url_split[3];
                            new_route_en += '/'+url_split[3];
                        }

                        route_set_nl(new_route_nl);
                        route_set_en(new_route_en);
                    }
                }
            }
        }
    }, [debug, location, routes_urls, translation_language]);

    return (
        <React.Fragment>
            <section className="navbar-section fixed-top">
                <div className="container">
                    <Navbar expanded={expanded} expand="md" variant="light" className="navbar-container">
                        
                        <div className="navbar-title-container">
                            <Link to={'/'+props.translation_language} className="navbar-title" onClick={() => set_expanded(false)}>CustomSoftware.nl</Link>
                            
                            <div className="navbar-divider d-none d-md-block"></div>
                        
                            <Link to="https://thissen.ai" target="_blank" className="d-none d-md-block">
                                <div className="navbar-item-offeredby">
                                    <div className="d-none d-md-block">
                                        <div className="navbar-item-offeredby-text d-none d-lg-block">
                                            {UtilTranslate(props.translation_data, 'navigation_offeredby', props.debug)}
                                        </div>
                                    </div>
                                    <span className="navbar-item-offeredby-title">Thissen AI<sup className="navbar-item-offeredby-title-trademark">®</sup></span>
                                </div>
                            </Link>
                        </div>

                        <Navbar.Toggle aria-controls="navbarScroll" data-bs-target="$navbarScroll" onClick={() => set_expanded(expanded ? false : 'expanded')} />
                        <Navbar.Collapse id="navbarScroll" className="navbar-justify-right">
                            <Nav>
                            { /* Multilanguage desktop */ }
                                <div className="d-none d-md-block">
                                    <div className="navbar-item-multilanguage-container">
                                        { props.translation_language === 'nl-NL' &&
                                            <div className="navbar-item-multilanguage-item-container">
                                                <div className="navbar-item-multilanguage-item">
                                                    <div className="navbar-item-multilanguage-flag-nl"></div>{UtilTranslate(props.translation_data, 'navigation_nl', props.debug)}
                                                </div>
                                            </div>
                                        }

                                        { props.translation_language === 'en-US' &&
                                            <div className="navbar-item-multilanguage-item-container">
                                                <div className="navbar-item-multilanguage-item">
                                                    <div className="navbar-item-multilanguage-flag-en"></div>{UtilTranslate(props.translation_data, 'navigation_en', props.debug)}
                                                </div>
                                            </div>
                                        }

                                        <div className="navbar-item-multilanguage-dropdown">
                                            { props.translation_language !== null && props.translation_language !== 'nl-NL' &&
                                                <Link to={route_nl} className="navbar-item-multilanguage-dropdown-item" onClick={() => set_expanded(false)}>
                                                    <div className="navbar-item-multilanguage-flag-nl"></div>{UtilTranslate(props.translation_data, 'navigation_nl', props.debug)}
                                                </Link>
                                            }

                                            { props.translation_language !== null && props.translation_language !== 'en-US' &&
                                                <Link to={route_en} className="navbar-item-multilanguage-dropdown-item" onClick={() => set_expanded(false)}>
                                                    <div className="navbar-item-multilanguage-flag-en"></div>{UtilTranslate(props.translation_data, 'navigation_en', props.debug)}
                                                </Link>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="navbar-listitem">
                                    <HashLink smooth to="#contactform" className="navbar-item" onClick={() => set_expanded(false)}>{UtilTranslate(props.translation_data, 'navigation_contactform', props.debug)}</HashLink>
                                </div>

                                { /* Multilanguage mobile */ }

                                { props.translation_language !== null && props.translation_language !== 'nl-NL' &&
                                    <div className="navbar-listitem d-block d-md-none">
                                        <Link to={route_nl} className="navbar-item" onClick={() => set_expanded(false)}><div className="navbar-item-multilanguage-flag-nl"></div>{UtilTranslate(props.translation_data, 'navigation_switch_nl', props.debug)}</Link>
                                    </div>
                                }

                                { props.translation_language !== null && props.translation_language !== 'en-US' &&
                                    <div className="navbar-listitem d-block d-md-none">
                                        <Link to={route_en}  className="navbar-item" onClick={() => set_expanded(false)}><div className="navbar-item-multilanguage-flag-en"></div>{UtilTranslate(props.translation_data, 'navigation_switch_en', props.debug)}</Link>
                                    </div>
                                }
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </section>
            <div className="navbar-section-spacing"></div>
        </React.Fragment>
    );
}

// Props: debug, ip_address, translation_data
function SectionPrivacy(props)
{
    // Content
    let debug = props.debug;
    let ip_address = props.ip_address;
    let translation_language = props.translation_language;
    const [content_data, content_set_data] = useState(null);
    useEffect(() => {
        if (translation_language !== null)
        {
            const get = async() => {
                try
                {
                    let params = {
                        keys: [
                            'privacy'
                        ],
                        language: translation_language
                    };

                    const response = await axios.get(ip_address+'/backend/customsoftware/api/content', { params });

                    content_set_data(response.data);
                    // <list> content text
                    
                    if (debug === true)
                        console.log(response.data);
                }
                
                catch (error)
                {
                    console.log(error);
                }
            }

            get();
        }
    }, [debug, ip_address, translation_language]);

    return (
        <React.Fragment>
            <section className="element-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ElementTitleH1
                                text={UtilTranslate(props.translation_data, 'navigation_privacy', props.debug)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            { content_data !== null &&
                                <div className="element-text" dangerouslySetInnerHTML={{ __html: UtilContent(DOMPurify.sanitize(content_data[0]), 'disclaimer', props.debug)}}></div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export { SectionBanner, SectionContent, SectionDisclaimer, SectionFooter, SectionForm, SectionIntro, SectionNavigation, SectionPrivacy };